const config = {
    APP_INFO: {
        APP_Name: "Grimax Pro",
        VERSION: "0.1"
    },
    API_URL: "https://servicemadisongym.grimax.id",
    QR_URL: "https://servicemadisongym.grimax.id",
    API_MOBILE:"https://servicems.grimax.id",

    ID_PROFILE:"GRIMAXCL231100001",
    NAMA_PROFILE:"Madison Gym",
    production_mode: true,
    is_demo : false,
    version_code : "1.0.2",

    API_DEFINITION: {
        "LOGIN": { "METHOD": "POST", "ENDPOINT": "/login_admin" },
        
        // LIST
        "GET_DASHBOARD_DATA": { "METHOD": "POST", "ENDPOINT": "/get_dashboard_data" },
        "GET_MEMBER": { "METHOD": "POST", "ENDPOINT": "/get_list_member" },
        "GET_PERIODE": { "METHOD": "POST", "ENDPOINT": "/get_periode_member" },
        "GET_TRAINER": { "METHOD": "POST", "ENDPOINT": "/get_list_trainer" },
        "GET_SUPPLIER": { "METHOD": "POST", "ENDPOINT": "/get_supplier_barang" },
        "GET_SATUAN": { "METHOD": "POST", "ENDPOINT": "/get_satuan_barang" },
        "GET_JENIS": { "METHOD": "POST", "ENDPOINT": "/get_jenis_barang" },
        "GET_BARANG": { "METHOD": "POST", "ENDPOINT": "/get_barang" },
        "GET_BARANGMASUK": { "METHOD": "POST", "ENDPOINT": "/get_barang_masuk" },
        "GET_BARANGKELUAR": { "METHOD": "POST", "ENDPOINT": "/get_barang_keluar" },
        "GET_PEMASUKAN": { "METHOD": "POST", "ENDPOINT": "/get_pemasukan" },
        "GET_PENGELUARAN": { "METHOD": "POST", "ENDPOINT": "/get_pengeluaran" },
        "GET_USER": { "METHOD": "POST", "ENDPOINT": "/get_admin" },
        "GET_LOG": { "METHOD": "POST", "ENDPOINT": "/get_log" },
        "GET_STUDIO": { "METHOD": "POST", "ENDPOINT": "/get_studio" },
        "GET_FASILITAS": { "METHOD": "POST", "ENDPOINT": "/get_fasilitas" },
        "GET_KONTRAK": { "METHOD": "POST", "ENDPOINT": "/get_kontrak" },
        "GET_SCHEDULE": { "METHOD": "POST", "ENDPOINT": "/get_schedule" },
        "GET_INFO": { "METHOD": "POST", "ENDPOINT": "/get_info" },
        "GET_ABSEN": { "METHOD": "POST", "ENDPOINT": "/get_absen_member" },
        "GET_ABSEN_ALL": { "METHOD": "POST", "ENDPOINT": "/get_absen_all" },

        "GET_PEMBAYARAN": { "METHOD": "POST", "ENDPOINT": "/get_pembayaran" },
        "GET_GALLERY": { "METHOD": "POST", "ENDPOINT": "/get_gallery" },
        "CHECK_ABSEN": { "METHOD": "POST", "ENDPOINT": "/check_absen" },
        "CONFIRM_ABSEN": { "METHOD": "POST", "ENDPOINT": "/confirm_absen" },
        "GET_TRIAL": { "METHOD": "POST", "ENDPOINT": "/get_trial" },

        //ADD
        "ADD_MEMBER": { "METHOD": "POST", "ENDPOINT": "/add_member" },
        "ADD_STUDIO": { "METHOD": "POST", "ENDPOINT": "/add_studio" },
        "ADD_PERIODE": { "METHOD": "POST", "ENDPOINT": "/add_periode" },
        "ADD_SUPPLIER": { "METHOD": "POST", "ENDPOINT": "/add_supplier" },
        "ADD_SATUAN": { "METHOD": "POST", "ENDPOINT": "/add_satuan" },
        "ADD_JENIS": { "METHOD": "POST", "ENDPOINT": "/add_jenis" },
        "ADD_TRAINER": { "METHOD": "POST", "ENDPOINT": "/add_trainer" },
        "ADD_FASILITAS": { "METHOD": "POST", "ENDPOINT": "/add_fasilitas" },
        "ADD_KONTRAK": { "METHOD": "POST", "ENDPOINT": "/add_kontrak" },
        "ADD_SCHEDULE": { "METHOD": "POST", "ENDPOINT": "/add_schedule" },
        "ADD_BARANG": { "METHOD": "POST", "ENDPOINT": "/add_barang" },
        "ADD_PEMASUKAN": { "METHOD": "POST", "ENDPOINT": "/add_pemasukan" },
        "ADD_PENGELUARAN": { "METHOD": "POST", "ENDPOINT": "/add_pengeluaran" },
        "ADD_BARANGMASUK": { "METHOD": "POST", "ENDPOINT": "/add_barangmasuk" },
        "ADD_USER": { "METHOD": "POST", "ENDPOINT": "/add_user" },
        "ADD_INFO": { "METHOD": "POST", "ENDPOINT": "/add_info" },
        "ADD_GALLERY": { "METHOD": "POST", "ENDPOINT": "/add_gallery" },

        //EDIT
        "EDIT_MEMBER": { "METHOD": "POST", "ENDPOINT": "/edit_member" },
        "EDIT_STUDIO": { "METHOD": "POST", "ENDPOINT": "/edit_studio" },
        "EDIT_PERIODE": { "METHOD": "POST", "ENDPOINT": "/edit_periode" },
        "EDIT_SUPPLIER": { "METHOD": "POST", "ENDPOINT": "/edit_supplier" },
        "EDIT_SATUAN": { "METHOD": "POST", "ENDPOINT": "/edit_satuan" },
        "EDIT_JENIS": { "METHOD": "POST", "ENDPOINT": "/edit_jenis" },
        "EDIT_TRAINER": { "METHOD": "POST", "ENDPOINT": "/edit_trainer" },
        "EDIT_FASILITAS": { "METHOD": "POST", "ENDPOINT": "/edit_fasilitas" },
        "EDIT_BARANG": { "METHOD": "POST", "ENDPOINT": "/edit_barang" },
        "EDIT_USER": { "METHOD": "POST", "ENDPOINT": "/edit_user" },
        "EDIT_PROFILE": { "METHOD": "POST", "ENDPOINT": "/edit_profile" },
        "EDIT_PASSWORD": { "METHOD": "POST", "ENDPOINT": "/edit_password" },
        "EDIT_QR": { "METHOD": "POST", "ENDPOINT": "/edit_qr" },

        //DELETE
        "DELETE_MEMBER": { "METHOD": "POST", "ENDPOINT": "/delete_member" },
        "DELETE_STUDIO": { "METHOD": "POST", "ENDPOINT": "/delete_studio" },
        "DELETE_PERIODE": { "METHOD": "POST", "ENDPOINT": "/delete_periode" },
        "DELETE_SUPPLIER": { "METHOD": "POST", "ENDPOINT": "/delete_supplier" },
        "DELETE_SATUAN": { "METHOD": "POST", "ENDPOINT": "/delete_satuan" },
        "DELETE_JENIS": { "METHOD": "POST", "ENDPOINT": "/delete_jenis" },
        "DELETE_TRAINER": { "METHOD": "POST", "ENDPOINT": "/delete_trainer" },
        "DELETE_FASILITAS": { "METHOD": "POST", "ENDPOINT": "/delete_fasilitas" },
        "DELETE_KONTRAK": { "METHOD": "POST", "ENDPOINT": "/delete_kontrak" },
        "DELETE_SCHEDULE": { "METHOD": "POST", "ENDPOINT": "/delete_schedule" },
        "DELETE_BARANGMASUK": { "METHOD": "POST", "ENDPOINT": "/delete_barangmasuk" },
        "DELETE_PEMASUKAN": { "METHOD": "POST", "ENDPOINT": "/delete_pemasukan" },
        "DELETE_PENGELUARAN": { "METHOD": "POST", "ENDPOINT": "/delete_pengeluaran" },
        "DELETE_BARANG": { "METHOD": "POST", "ENDPOINT": "/delete_barang" },
        "DELETE_USER": { "METHOD": "POST", "ENDPOINT": "/delete_user" },
        "DELETE_GALLERY": { "METHOD": "POST", "ENDPOINT": "/delete_gallery" },


        //OTHER
        "ACTIVATE_MEMBER": { "METHOD": "POST", "ENDPOINT": "/activation_member" },
        "ACTIVATE_TRAINER": { "METHOD": "POST", "ENDPOINT": "/activation_trainer" },
        "ACTIVATE_KONTRAK": { "METHOD": "POST", "ENDPOINT": "/activation_kontrak" },
        "ACTIVATE_TRIAL": { "METHOD": "POST", "ENDPOINT": "/activation_trial" },


        "DEACTIVATE_TRAINER": { "METHOD": "POST", "ENDPOINT": "/deactivation_trainer" },
        "GET_DETAIL_GYM": { "METHOD": "POST", "ENDPOINT": "/get_detail_gym" },


        //LATESTID
        "GET_LATEST_MEMBERID": { "METHOD": "POST", "ENDPOINT": "/generate_idmember" },
        "GET_LATEST_BARANGID": { "METHOD": "POST", "ENDPOINT": "/generate_idbarang" },
        "GET_LATEST_TRAINERID": { "METHOD": "POST", "ENDPOINT": "/generate_idtrainer" },
        "GET_LATEST_KONTRAKID": { "METHOD": "POST", "ENDPOINT": "/generate_idkontrak" },
        "GET_LATEST_BARANGKELUARID": { "METHOD": "POST", "ENDPOINT": "/generate_idbarangkeluar" },
        "GET_LATEST_PEMASUKANID": { "METHOD": "POST", "ENDPOINT": "/generate_idpemasukan" },
        "GET_LATEST_BARANGMASUKID": { "METHOD": "POST", "ENDPOINT": "/generate_idbarangmasuk" },
        "GET_LATEST_PENGELUARANID": { "METHOD": "POST", "ENDPOINT": "/generate_idpengeluaran" },


        //REPORT
        "REPORT": { "METHOD": "GET", "ENDPOINT": "/show_report" },


    },
   
}
export default config;
