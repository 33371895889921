import config from './config';


const fetchingData = (endpoint, data) => {
    return new Promise((resolve, reject) => {
        let API = config.API_URL + config.API_DEFINITION[endpoint].ENDPOINT;

        const headers = new Headers();
        headers.append('Accept', '*/*');
        let requestObject = {
            method: config.API_DEFINITION[endpoint].METHOD,
            headers: headers,
        }
        if (config.API_DEFINITION[endpoint].METHOD == 'GET') {
            API = API + data;
            console.log("APi : ",API);
            fetch(API).then((json) => {
                let tmpResult = json;
                resolve(tmpResult);
            }).catch((err) => {
                console.log("err : ", err);
                reject(err);
            })
        } else {
            requestObject.body = JSON.stringify(data);
            fetch(API, requestObject).
                then((response) => response.json()).
                then((json) => {
                    let tmpResult = json;
                    if(endpoint == 'ADD_MEMBER'){
                        resolve(tmpResult);
                    }else{
                        if (json.code == '200') {
                            resolve(tmpResult);
                        } else {
                            reject('error');
                        }
                    }
                })
                .catch((err) => {
                    console.log("err : ", err);
                    reject(err);
                })
                .catch((err) => {
                    console.log("err : ", err);
                    reject(err);
                })
        }



    })
}

const apiService = {
    invoke(endpoint, data, success, error) {
        prepareFetch(endpoint, data, success, error);
    }
}

const prepareFetch = (endpoint, data, success, error) => {
    fetchingData(endpoint, data).then((responseFetch) => {
        success(responseFetch);
    }, (errFetch) => {
        error(errFetch);
    })

}

export default apiService;
