import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));


const memberData = lazy(() => import('./membership/member/data'));
const addMember = lazy(() => import('./membership/member/add'));
const editMember = lazy(() => import('./membership/member/edit'));
const activationMember = lazy(() => import('./membership/member/activation'));
const detailMember = lazy(() => import('./membership/member/detail'));

const studioData = lazy(() => import('./membership/studio/data'));
const addStudio = lazy(() => import('./membership/studio/add'));
const editStudio = lazy(() => import('./membership/studio/edit'));

const trialData = lazy(() => import('./membership/trial/data'));

const checkinData = lazy(() => import('./membership/checkin/data'));

const kelasData = lazy(() => import('./class/data'));
const addKelas = lazy(() => import('./class/add'));
const editKelas = lazy(() => import('./class/edit'));
const calendarKelas = lazy(() => import('./class/calendar'));

const periodeData = lazy(() => import('./membership/periode/data'));
const addPeriode = lazy(() => import('./membership/periode/add'));
const editPeriode = lazy(() => import('./membership/periode/edit'));

const trainerData = lazy(() => import('./membership/trainer/data'));
const addTrainer = lazy(() => import('./membership/trainer/add'));
const editTrainer = lazy(() => import('./membership/trainer/edit'));
const detailTrainer = lazy(() => import('./membership/trainer/detail'));



const fasilitasData = lazy(() => import('./fasilitas/data'));
const addFasilitas = lazy(() => import('./fasilitas/add'));
const editFasilitas = lazy(() => import('./fasilitas/edit'));

const supplierData = lazy(() => import('./barang/supplier/data'));
const addSupplier = lazy(() => import('./barang/supplier/add'));
const editSupplier = lazy(() => import('./barang/supplier/edit'));


const satuanData = lazy(() => import('./barang/satuan/data'));
const addSatuan = lazy(() => import('./barang/satuan/add'));
const editSatuan = lazy(() => import('./barang/satuan/edit'));


const jenisData = lazy(() => import('./barang/jenis/data'));
const addJenis = lazy(() => import('./barang/jenis/add'));
const editJenis = lazy(() => import('./barang/jenis/edit'));


const barangData = lazy(() => import('./barang/barang/data'));
const addBarang = lazy(() => import('./barang/barang/add'));
const editBarang = lazy(() => import('./barang/barang/edit'));

const barangMasuk = lazy(() => import('./transaksi/barangmasuk/data'));
const addBarangMasuk = lazy(() => import('./transaksi/barangmasuk/add'));

const addBarangKeluar = lazy(() => import('./transaksi/barangkeluar/add'));


const financeIncome = lazy(() => import('./finance/income/data'));
const financeExpend = lazy(() => import('./finance/expend/data'));
const addExpend = lazy(() => import('./finance/expend/add'));
const addIncome = lazy(() => import('./finance/income/add'));


const kontrakData = lazy(() => import('./kontrak/data'));
const addKontrak = lazy(() => import('./kontrak/add'));
const detailKontrak = lazy(() => import('./kontrak/detail'));
const reportData = lazy(() => import('./report/data'));


const scheduleData = lazy(() => import('./schedule/data'));
const addSchedule = lazy(() => import('./schedule/add'));

const scheduleCalendar = lazy(() => import('./schedule/calendar'));

const detailInvoice = lazy(() => import('./invoice/detail'));

const settingsData = lazy(() => import('./settings/settings'));
const ubahPassword = lazy(() => import('./settings/ubahpassword'));



const userData = lazy(() => import('./user/data'));
const addUser = lazy(() => import('./user/add'));
const editUser = lazy(() => import('./user/edit'));


const galleryData = lazy(() => import('./gallery/data'));
const addGallery = lazy(() => import('./gallery/add'));

const userLog = lazy(() => import('./user/log'));

const addInfo = lazy(() => import('./info/add'));
const infoData = lazy(() => import('./info/data'));

const Login = lazy(() => import('./auth/Login'));
const Register1 = lazy(() => import('./auth/Register'));


class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/register" component={Register1} />

          <Route path="/dashboard" component={Dashboard} />

          <Route path="/membership/data" component={memberData} />
          <Route path="/membership/add" component={addMember} />
          <Route path="/membership/edit" component={editMember} />
          <Route path="/membership/activation" component={activationMember} />
          <Route path="/membership/detail" component={detailMember} />

          <Route path="/studio/data" component={studioData} />
          <Route path="/studio/add" component={addStudio} />
          <Route path="/studio/edit" component={editStudio} />
          <Route path="/trial/data" component={trialData} />

          <Route path="/checkin/data" component={checkinData} />

          <Route path="/class/data" component={kelasData} />
          <Route path="/class/add" component={addKelas} />
          <Route path="/class/edit" component={editKelas} />
          <Route path="/class/calendar" component={calendarKelas} />

          <Route path="/fasilitas/data" component={fasilitasData} />
          <Route path="/fasilitas/add" component={addFasilitas} />
          <Route path="/fasilitas/edit" component={editFasilitas} />


          <Route path="/periode/data" component={periodeData} />
          <Route path="/periode/add" component={addPeriode} />
          <Route path="/periode/edit" component={editPeriode} />

          <Route path="/trainer/data" component={trainerData} />
          <Route path="/trainer/add" component={addTrainer} />
          <Route path="/trainer/edit" component={editTrainer} />
          <Route path="/trainer/detail" component={detailTrainer} />


          <Route path="/supplier/data" component={supplierData} />
          <Route path="/supplier/add" component={addSupplier} />
          <Route path="/supplier/edit" component={editSupplier} />

          <Route path="/satuan/data" component={satuanData} />
          <Route path="/satuan/add" component={addSatuan} />
          <Route path="/satuan/edit" component={editSatuan} />

          <Route path="/jenis/data" component={jenisData} />
          <Route path="/jenis/add" component={addJenis} />
          <Route path="/jenis/edit" component={editJenis} />


          <Route path="/barang/data" component={barangData} />
          <Route path="/barang/add" component={addBarang} />
          <Route path="/barang/edit" component={editBarang} />

          <Route path="/kontrak/data" component={kontrakData} />
          <Route path="/kontrak/add" component={addKontrak} />
          <Route path="/kontrak/detail" component={detailKontrak} />

          <Route path="/schedule/data" component={scheduleData} />
          <Route path="/schedule/add" component={addSchedule} />
          <Route path="/schedule/calendar" component={scheduleCalendar} />

          <Route path="/invoice/detail" component={detailInvoice} />


          <Route path="/bmasuk/data" component={barangMasuk} />
          <Route path="/bmasuk/add" component={addBarangMasuk} />

          <Route path="/bkeluar/add" component={addBarangKeluar} />


          <Route path="/finance/income/data" component={financeIncome} />
          <Route path="/finance/expend/data" component={financeExpend} />
          <Route path="/finance/expend/add" component={addExpend} />
          <Route path="/finance/income/add" component={addIncome} />

          <Route path="/report/data" component={reportData} />

          <Route path="/settings/data" component={settingsData} />
          <Route path="/settings/ubahpassword" component={ubahPassword} />

          <Route path="/gallery/data" component={galleryData} />
          <Route path="/gallery/add" component={addGallery} />

          <Route path="/user/data" component={userData} />
          <Route path="/user/add" component={addUser} />
          <Route path="/user/edit" component={editUser} />

          <Route path="/log" component={userLog} />

          <Route path="/info/add" component={addInfo} />
          <Route path="/info/data" component={infoData} />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;