import React, { Component } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import { reactLocalStorage } from 'reactjs-localstorage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Clock from 'react-live-clock';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import config from '../utils/config';
import common from '../utils/common';
import apiService from '../utils/apiService';
import Popup from 'reactjs-popup';
import ringer from "../assets/notification.mp3";
import 'reactjs-popup/dist/index.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningTime: 1000 * 60 * 10,
      signoutTime: 1000 * 60 * 15,
      greeting: "",
      dataUser: {},
      isLogin: false,
      absens: false,
      memberData: {},
      absenData: {},
      totalBalance:0
    };
  }


  componentDidMount() {
    let datacred = reactLocalStorage.get('credential');
    if (datacred) {
      this.setState({ dataUser: JSON.parse(datacred), isLogin: true }, () => {
        this._initiateAbsenceCheck();
      });
    }
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    let time = new Date();
    let hour = time.getHours();
    if (hour > 0 && hour < 12) {
      this.setState({ greeting: "Good Morning", icon: "light" });
    } else if (hour >= 12 && hour < 15) {
      this.setState({ greeting: "Good Afternoon", icon: "light" });
    } else if (hour >= 15 && hour < 18) {
      this.setState({ greeting: "Good Afternoon", icon: "light" });
    } else if (hour >= 18 && hour <= 23) {
      this.setState({ greeting: "Good Evening", icon: "dark" });
    } else {
      this.setState({ greeting: "Good Evening", icon: "dark" });
    }
    this.setTimeout();
    this.onRouteChanged();
    
    document.addEventListener("keydown", this.listener);
  }
  componentWillUnmount() {
    document.addEventListener("keydown", this.listener);
  }
  listener = (event) => {
    if (event.code === "Enter") {
      event.preventDefault();
    }
  };

  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    // window.alert("You will be logged out automatically in 1 minute")
    // console.log('You will be logged out automatically in 1 minute.');
  };

  logout = () => {
    // Send a logout request to the API
    // console.log('Sending a logout request to the API...');
    this.destroy();
  };

  destroy = () => {
    clearInterval();
    //clear the session
    reactLocalStorage.remove('islogin');
    reactLocalStorage.remove('credential');
    this.setState({ isLogin: false }, () => {
      window.location.assign('/login');
    });
  };

  _confirmAbsen(params) {
    clearInterval();
    let tmpRequest = {
      client_secret: this.state.dataUser.client_secret,
      id_absen: this.state.absenData.id_absen
    };
    apiService.invoke("CONFIRM_ABSEN", tmpRequest,
      (success) => {
        this._initiateAbsenceCheck();
        if (params == 'member') {
          this._goto('/membership/detail', this.state.memberData)
        } else if (params == 'activation') {
          this._goto('/membership/activation', this.state.memberData)
        }
      }, (error) => {
      });
  }

  _initiateAbsenceCheck = () => {
    const audio = new Audio(ringer);
    setInterval(() => {
      this.setState({totalBalance: reactLocalStorage.get('totalBalance')});
      let tmpRequest = {
        client_secret: this.state.dataUser.client_secret,
      };
      apiService.invoke("CHECK_ABSEN", tmpRequest,
        (success) => {
          if (success.data_absen) {
            this.setState({ absens: true, memberData: success.data_member, absenData: success.data_absen }, () => {
              audio.play();
            });
          }
        }, (error) => {
        });
    }, 3000);
  }

  _goto(v, params) {
    this.props.history.push(v, params);
  }
  render() {
    
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar history={this.props.history} i18n={this.props.i18n} /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar i18n={this.props.i18n} totalBalance={this.state.totalBalance} /> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer i18n={this.props.i18n} /> : '';
    const detailData = this.state.memberData;
    const absenData = this.state.absenData;
    return (
      <>
        <Popup open={this.state.absens} closeOnEscape={false} closeOnDocumentClick={false} position="right center" contentStyle={{ backgroundColor: "transparent", borderColor: "transparent" }}>
          <div className='card' style={{ borderRadius: 12 }}>
            <div className='row p-3 pt-5'>
              <div className='col-md-12'>
                <center>
                  {
                    absenData.status_absen == 1 ? (
                      <h2>Check - In Succeed <img className="rounded" width={35} src={require("../assets/images/check.gif")} /></h2>
                    ) : (
                      <h2>Check - In Failed <img className="rounded" width={35} src={require("../assets/images/fail.gif")} /></h2>
                    )
                  }

                  <h4>{common.dateFormat(absenData.tanggal_absen)} | {absenData.waktu_absen}</h4>
                </center>
              </div>

            </div>
            <div className="p-3 row">

              <div className="col-md-8">
                <table className="table table-striped">
                  <tr>
                    <th><Trans>Full Name</Trans></th>
                    <td>{detailData.nama_member}</td>
                  </tr>
                  <tr>
                    <th><Trans>Email</Trans></th>
                    <td>{detailData.email_member == '' ? '-' : detailData.email_member}</td>
                  </tr>
                  <tr>
                    <th><Trans>Phone</Trans></th>
                    <td>{detailData.telp_member == '' ? '-' : detailData.telp_member}</td>
                  </tr>
                  <tr>
                    <th><Trans>Start</Trans></th>
                    <td>{common.dateFormat(detailData.tanggal_aktifasi)}</td>
                  </tr>
                  <tr>
                    <th><Trans>Expire</Trans></th>
                    <td>{common.dateFormat(detailData.habis_member)}</td>
                  </tr>
                  <tr>
                    <th><Trans>Status</Trans></th>
                    <td>
                      {detailData.status_member == 1 ? (
                        <span className="badge badge-outline-success badge-sm">
                          <Trans>Active</Trans>
                        </span>
                      ) : (
                        <span className="badge badge-outline-danger badge-sm">
                          <Trans>No Active</Trans>
                        </span>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-md-4">
                <div id="fotoMems">
                  <img className="img-thumbnail rounded" src={detailData.foto_member ? config.API_URL + '/assets/img/member/' + detailData.foto_member : require("../assets/images/member/user.png")} style={{ width: '100%', borderRadius: 30 }} />
                </div>
              </div>

            </div>
            <div className='p-3'>
              <center>
                <button onClick={() => this.setState({ absens: false }, () => this._confirmAbsen('confirm'))} className='btn btn-lg btn-primary mr-2'>
                  Confirm
                </button>
                {
                  absenData.status_absen == 1 ? null : (
                    <button onClick={() => this.setState({ absens: false }, () => this._confirmAbsen('activation'))} className='btn btn-lg btn-primary mr-2'>
                      Activate Member
                    </button>
                  )
                }
                <button onClick={() => this.setState({ absens: false }, () => this._confirmAbsen('member'))} className='btn btn-lg btn-success'>
                  Membership Detail
                </button>
              </center>
            </div>
          </div>
        </Popup>
        <ToastContainer
          position="bottom-center"
          autoClose={7000}
          hideProgressBar={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="container-scroller">
          {sidebarComponent}
          <div className="container-fluid page-body-wrapper">
            {navbarComponent}
            <div className="main-panel" >
              <div className="content-wrapper">
                {
                  !this.state.isFullPageLayout ? (
                    <div className="page-header mb-2">
                      <h3><Trans>{this.state.greeting}</Trans>, {config.NAMA_PROFILE} {config.is_demo ? '| Demo' : ''}</h3>
                      {
                        isMobile ? null : (
                          <Clock format={'HH:mm:ss | dddd, D MMMM YYYY'} ticking={true} style={{ fontSize: 16,marginTop:-5 }} />
                        )
                      }
                    </div>
                  ) : null
                }
                <AppRoutes />
              </div>
              {footerComponent}
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // console.log("ROUTE CHANGED");
    const { i18n } = this.props;
    const body = document.querySelector('body');
    let lang = reactLocalStorage.get('lang');
    // console.log("lang : ", lang);
    if (lang == 'en') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('id');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/register'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withTranslation()(withRouter(App));
