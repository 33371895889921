import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isMobile } from 'react-device-detect';
import "../../i18n";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../utils/config';

class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      ismobile: false,
      key: ""
    };
  }

  componentDidMount() {
    let userDatas = reactLocalStorage.get('credential');
    if (userDatas) {
      let dataUser = JSON.parse(userDatas);
      this.setState({ userData: dataUser });
    }
    if (isMobile) {
      this.setState({ ismobile: isMobile }, () => {
        document.body.classList.toggle('sidebar-icon-only');
      });
    }

    const { i18n } = this.props;
    let lang = reactLocalStorage.get('en');
    if (lang == 'en') {
      i18n.changeLanguage('en');

    } else {
      i18n.changeLanguage('id');

    }
  }

  _onChangeLang(v) {
    const { i18n } = this.props;
    if (v === 'en') {
      reactLocalStorage.set('lang', 'en');
      i18n.changeLanguage('en');
      toast.success("Successfuly change language to English");
    } else {
      reactLocalStorage.set('lang', 'id');
      i18n.changeLanguage('id');
      toast.success("Berhasil mengubah bahasa ke Bahasa Indonesia");
    }
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }


  _doLogout = () => {
    reactLocalStorage.remove('islogin');
    reactLocalStorage.remove('credential');
    this._goto('/login');
  }

  _goto(v, param) {
    this.props.history.push(v, param);
  }

  _onSearch = (evt) => {
    let obj = {
      from: 'searchMember',
      key: this.state.key
    }
    this._goto('membership/data', obj);

  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this._onSearch();
    }
  }

  _onChangeSearch = (val) => {
    this.setState({ key: val })
  }





  render() {
    const { userData, ismobile } = this.state;
    return (
      <>
        <nav className="navbar p-0 fixed-top d-flex flex-row">
          <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
            <Link className="navbar-brand" to="/"><img src={require('../../assets/images/favicon.png')} alt="logo" /></Link>
          </div>
          <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <button className="navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
              <span className="mdi mdi-menu"></span>
            </button>
            <ul class="navbar-nav w-100">
              <li class="nav-item w-100">
                <div class="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                  <input onChangeCapture={(val) => this._onChangeSearch(val.target.value)} onKeyDown={(e) => this._handleKeyDown(e)} type="text" class="form-control text-white" placeholder="Search Member" />
                </div>
              </li>
            </ul>

            <ul className="navbar-nav navbar-nav-right">
              {
                this.state.ismobile ? null : (
                  <a style={{ width: 200 }} target="_blank" href={"https://grandbase.grimax.id/assets/img/qrcode/" + config.ID_PROFILE + ".png"} className="btn btn-secondary no-caret mr-2">
                    Check - In QR <span className='mdi mdi-qrcode'></span>
                  </a>
                )
              }

              <Dropdown alignRight as="li" className="nav-item d-none d-lg-block">
                <Dropdown.Toggle className="nav-link btn btn-primary create-new-button no-caret">
                  + <Trans>Create New</Trans>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu">
                  <Dropdown.Item onClick={() => this._goto('/membership/add')} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-account text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1"><Trans>Member</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => this._goto('/barang/add')} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-buffer text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1"><Trans>Product</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => this._goto('/info/add')} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-layers text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1"><Trans>Annoucement</Trans></p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown alignRight as="li" className="nav-item border-left">
                <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
                  <i className="mdi mdi-flag"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <h6 className="p-3 mb-0"><Trans>Language</Trans></h6>
                  <Dropdown.Divider />
                  <Dropdown.Item className="dropdown-item preview-item" onClick={() => this._onChangeLang('id')}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <img src={require('../../assets/images/indo-flag.png')} />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1"><Trans>Bahasa Indonesia</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item className="dropdown-item preview-item" onClick={() => this._onChangeLang('en')}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <img src={require('../../assets/images/eng-flag.png')} />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject mb-1"><Trans>English</Trans></h6>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown alignRight as="li" className="nav-item">
                <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                  <div className="navbar-profile">
                    <img className="img-xs rounded-circle" src={userData.foto ? userData.foto : require('../../assets/images/member/user.png')} alt="profile" />
                    <p className="mb-0 d-none d-sm-block navbar-profile-name"><Trans>{userData.nama}</Trans></p>
                    <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                  <h6 className="p-3 mb-0">{config.NAMA_PROFILE}</h6>
                  <Dropdown.Divider />
                  <Dropdown.Item className="preview-item" target="_blank" href={"https://grandbase.grimax.id/assets/img/qrcode/" + config.ID_PROFILE + ".png"}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-qrcode text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">Check - In QR Code</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this._goto('/settings/data')} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1"><Trans>Settings</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={(evt) => this._doLogout()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1"><Trans>Log Out</Trans></p>
                    </div>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
